<template>
  <Page>
    <div class="consents">
      <template v-if="hasUnsignableConsents">
        <consents-acceptance-pending
          :on-logout="onLogout"
          :user-name="userName"
          :software-name="softwareName"
        ></consents-acceptance-pending>
      </template>
      <div v-else>
        <div class="consents__title">3Shape Terms and Conditions</div>

        <div class="consents__greetings">Hi, {{ userName }}</div>

        <form
          ref="form"
          method="POST"
          action="/Consent"
        >
          <div
            v-if="cloudStorage"
            class="consents__cloud-storage-container"
          >
            <cloud-storage
              :data="cloudStorage"
              :selected-option="cloudStorageSelectedOption"
              :on-option-click="onCloudStorageOptionClick"
            ></cloud-storage>

            <input
              type="hidden"
              :name="`AgreementVersionInput[${cloudStorage.index}].AgreementId`"
              :value="cloudStorage.AgreementId"
            />
            <input
              type="hidden"
              :name="`AgreementVersionInput[${cloudStorage.index}].VersionId`"
              :value="cloudStorage.VersionId"
            />
            <input
              type="hidden"
              :name="`AgreementVersionInput[${cloudStorage.index}].RequirementOptions`"
              :value="cloudStorage.RequirementOptions"
            />
            <input
              v-if="isCloudStorageAccepted"
              type="hidden"
              :name="`AgreementVersionInput[${cloudStorage.index}].IsConsented`"
              value="True"
            />
          </div>

          <div
            v-if="companyConsents && companyConsents.length"
            class="consents__agreements-container"
          >
            <div class="consents__agreements-description-container">
              <img
                class="consents__agreements-image"
                :src="images.company"
                alt=""
              />
              <div class="consents__agreements-description">
                As a user of your company's 3Shape Software, ensure to review and accept 3Shape's
                legal agreements.
              </div>
            </div>
            <div class="consents__list">
              <div
                v-for="(consent, index) in companyConsents"
                :key="consent.AgreementId"
                class="consents__list-item"
              >
                <input
                  type="hidden"
                  :name="`AgreementVersionInput[${consent.index}].AgreementId`"
                  :value="consent.AgreementId"
                />
                <input
                  type="hidden"
                  :name="`AgreementVersionInput[${consent.index}].VersionId`"
                  :value="consent.VersionId"
                />
                <input
                  type="hidden"
                  :name="`AgreementVersionInput[${consent.index}].RequirementOptions`"
                  :value="consent.RequirementOptions"
                />

                <Checkbox
                  :name="`AgreementVersionInput[${consent.index}].IsConsented`"
                  value="True"
                  :checked="consent.checked"
                  :disabled="!consent.CanAccept"
                  :auto-qa-id="`company-agreement-checkbox-${consent.index}`"
                  :onChange="
                    () => {
                      onCheckboxChange(index, 'company')
                    }
                  "
                >
                  <div
                    class="consents__checkbox-label"
                    :class="{
                      'consents__checkbox-label_type_required': consent.RequirementOptions === 1
                    }"
                  >
                    {{ consent.Label }}

                    <Link
                      :auto-qa-id="`company-agreement-details-link-${consent.index}`"
                      :onClick="
                        (event) => {
                          onConsentClick(event, consent)
                        }
                      "
                    >
                      {{ consent.Name }}
                    </Link>
                  </div>
                </Checkbox>
              </div>
            </div>

            <div class="consents__required-fields-msg">Required fields</div>
          </div>

          <div
            v-if="userConsents && userConsents.length"
            class="consents__agreements-container"
          >
            <div class="consents__agreements-description-container">
              <img
                class="consents__agreements-image"
                :src="images.user"
                alt=""
              />
              <div class="consents__agreements-description">
                As a user of 3Shape Software, you are responsible for personally reviewing and
                accepting the legal agreements from 3Shape for your own usage.
              </div>
            </div>
            <div class="consents__list">
              <div
                v-for="(consent, index) in userConsents"
                :key="consent.AgreementId"
                class="consents__list-item"
              >
                <input
                  type="hidden"
                  :name="`AgreementVersionInput[${consent.index}].AgreementId`"
                  :value="consent.AgreementId"
                />
                <input
                  type="hidden"
                  :name="`AgreementVersionInput[${consent.index}].VersionId`"
                  :value="consent.VersionId"
                />
                <input
                  type="hidden"
                  :name="`AgreementVersionInput[${consent.index}].RequirementOptions`"
                  :value="consent.RequirementOptions"
                />

                <Checkbox
                  :name="`AgreementVersionInput[${consent.index}].IsConsented`"
                  value="True"
                  :checked="consent.checked"
                  :disabled="!consent.CanAccept"
                  :auto-qa-id="`user-agreement-checkbox-${consent.index}`"
                  :onChange="
                    () => {
                      onCheckboxChange(index, 'user')
                    }
                  "
                >
                  <div
                    class="consents__checkbox-label"
                    :class="{
                      'consents__checkbox-label_type_required': consent.RequirementOptions === 1
                    }"
                  >
                    {{ consent.Label }}

                    <Link
                      :auto-qa-id="`user-agreement-details-link-${consent.index}`"
                      :onClick="
                        (event) => {
                          onConsentClick(event, consent)
                        }
                      "
                    >
                      {{ consent.Name }}
                    </Link>
                  </div>
                </Checkbox>
              </div>
            </div>

            <div class="consents__required-fields-msg">Required fields</div>
          </div>

          <div class="consents__button-container">
            <Button
              :loading="loading.submit"
              :disabled="isSubmitDisabled"
              auto-qa-id="consents-submit-button"
              :onClick="onSubmit"
            >
              Submit
            </Button>
          </div>

          <input
            type="hidden"
            name="button"
            value="yes"
          />

          <input
            type="hidden"
            name="ReturnUrl"
            :value="returnUrl"
          />

          <input
            type="hidden"
            name="__RequestVerificationToken"
            :value="antiForgeryToken"
          />
        </form>

        <Modal
          v-if="modals.consentDetails"
          auto-qa-id="agreement-details-modal"
          :onClose="
            () => {
              toggleModal('consentDetails')
            }
          "
        >
          <pdf-viewer :pdfUrl="pdfUrl" />
        </Modal>
      </div>
    </div>
  </Page>
</template>

<script src="./Consents.js"></script>

<style src="./Consents.scss" lang="scss" scoped></style>
