import { ref, reactive, onMounted } from 'vue'
import { Dropdown, Button } from 'account-ui-components'
import Page from '@/components/Page/Page.vue'

export default {
  name: 'CompanySelection',

  props: {
    action: {
      type: String,
      required: true
    },
    defaultCompanyId: {
      type: String,
      required: true
    },
    defaultCompanyName: {
      type: String,
      required: true
    },
    companies: {
      type: Array,
      required: true
    }
  },

  components: {
    Dropdown,
    Button,
    Page
  },

  setup(props) {
    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')
    const antiForgeryToken = antiForgeryField && antiForgeryField.value
    const urlParams = new URLSearchParams(window.location.search)
    const returnUrl = urlParams.get('returnUrl') || urlParams.get('ReturnUrl')
    const userCode = urlParams.get('userCode')
    const company = ref(props.defaultCompanyId)
    const form = ref(null)
    const companiesData =
      props.companies &&
      props.companies.map(({ Text, Value }) => ({
        value: Value,
        label: Text
      }))
    const companiesDataWithGroups =
      props.companies &&
      reactive([
        {
          title: 'Primary company',
          children: [{ value: props.defaultCompanyId, label: props.defaultCompanyName }]
        },
        {
          title: 'Other companies',
          children: companiesData
        }
      ])
    const loading = reactive({
      button: false
    })

    const submitForm = () => {
      if (company.value) {
        loading.button = true

        form.value.submit()
      }
    }

    const onKeyDown = (event) => {
      if (event.code === 'Enter') {
        submitForm()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', onKeyDown)
    })

    const onChange = ({ value }) => {
      company.value = value
    }

    return {
      antiForgeryToken,
      returnUrl,
      userCode,
      company,
      loading,
      form,
      onChange,
      submitForm,
      companiesData,
      companiesDataWithGroups
    }
  }
}
