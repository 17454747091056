import { Button, Title } from 'account-ui-components'
import { ref } from 'vue'
import Page from '@/components/Page/Page.vue'

export default {
  name: 'Logout',
  props: {
    logoutId: {
      type: String,
      required: true
    },
    antiForgeryToken: {
      type: String,
      required: true
    }
  },
  components: {
    Page,
    Title,
    Button
  },

  setup() {
    const form = ref(null)

    const onSubmit = () => {
      form.value.submit()
    }

    return {
      form,
      onSubmit
    }
  }
}
