<template>
  <div class="external-login">
    <div class="external-login__title">
      Use single sign-on

      <div
        class="external-login__title-icon"
        v-tooltip="{
          content: `
              A single sign-on is a service provided by for instance Microsoft or Google.
              The service must be integrated by your IT organization before it can be used.
          `,
          offset: [8, 8]
        }"
      >
        <img
          :src="images.questionMark"
          alt=""
        />
      </div>
    </div>

    <form
      method="post"
      :action="action"
    >
      <div
        v-for="provider in providers"
        :key="provider"
        class="external-login__button-container"
      >
        <button
          class="external-login__btn"
          type="submit"
          name="provider"
          :value="provider"
          :data-auto-qa-id="`${provider.toLowerCase()}-sign-in`"
        >
          <img
            class="external-login__btn-icon"
            :src="images[provider.toLowerCase()]"
            alt=""
          />

          Sign in with {{ provider }}
        </button>
      </div>

      <input
        type="hidden"
        name="__RequestVerificationToken"
        :value="antiForgeryToken"
      />

      <input
        type="hidden"
        name="platform"
        :value="platform"
      />
      <input
        type="hidden"
        name="externalLoginInitiator"
        value="registration"
      />
    </form>
  </div>
</template>

<script src="./ExternalLogin.js"></script>

<style src="./ExternalLogin.scss" lang="scss" scoped></style>
