<template>
  <Page>
    <div class="forgot-password">
      <div class="forgot-password__stepper-container">
        <Stepper
          :steps="steps"
          activeStep="email"
        />
      </div>

      <form
        ref="form"
        method="post"
        action="/Account/ForgotPassword"
      >
        <div class="forgot-password__title">3Shape Account recovery</div>

        <div class="forgot-password__sign-in-link-container">
          <a
            class="forgot-password__sign-in-link"
            href="/account/login"
            data-auto-qa-id="sign-in-link"
          >
            Sign in
          </a>

          instead
        </div>

        <div class="forgot-password__input-container">
          <Input
            label="Email"
            name="email"
            required
            hint="If you have forgotten your password or need to activate your account,
                        please enter your email."
            :value="email"
            :error="errors.email"
            auto-qa-id="email-input"
            :onChange="onInputChange"
            :onBlur="onInputBlur"
            @keydown="onKeyDown"
          />
        </div>

        <div class="forgot-password__button-container">
          <Button
            v-show="!isLoading"
            block
            auto-qa-id="submit-button"
            :onClick="onSubmit"
          >
            Submit
          </Button>

          <Preloader v-show="isLoading" />
        </div>

        <input
          type="hidden"
          name="__RequestVerificationToken"
          :value="antiForgeryToken"
        />
      </form>
    </div>
  </Page>
</template>

<script src="./ForgotPassword.js"></script>

<style src="./ForgotPassword.scss" lang="scss" scoped></style>
