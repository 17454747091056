<template>
  <div
    class="snackbar"
    :class="{
      snackbar_theme_red: theme === 'red'
    }"
    :data-auto-qa-id="autoQaId"
  >
    <div class="snackbar__text">
      {{ message }}
    </div>

    <div
      class="snackbar__close-icon"
      title="Close"
      @click="onClose"
    >
      <Icon name="close" />
    </div>
  </div>
</template>

<script src="./Snackbar.js"></script>

<style src="./Snackbar.scss" lang="scss" scoped></style>
