<template>
  <Page>
    <div class="error">
      <img
        class="error__img"
        :src="images.tooth"
        alt=""
      />

      <div class="error__title-container">
        <Title v-if="errorTitle">
          {{ errorTitle }}
        </Title>
        <Title v-else>We apologize! Something unexpected happened</Title>
      </div>

      <div
        v-if="errorDescription"
        class="error__text"
      >
        <p>
          {{ errorDescription }}
        </p>
      </div>
      <div
        v-else
        class="error__text"
      >
        Please try to reload this page and if the issue persists, contact your customer support
        representative or 3Shape at
        <Link
          auto-qa-id="contact-us-link"
          href="https://www.3shape.com/en/contact-us"
          target="_blank"
        >
          https://www.3shape.com/en/contact-us
        </Link>
      </div>
    </div>
  </Page>
</template>

<script src="./Error.js"></script>

<style src="./Error.scss" lang="scss" scoped></style>
