import Preloader from '@/uikit/Preloader/Preloader.vue'
import Header from '@/components/Header/Header.vue'

const Page = {
  name: 'Page',

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Header,
    Preloader
  }
}

export default Page
