<template>
  <div class="activate-confirmation">
    <div class="activate-confirmation__preloader">
      <div class="activate-confirmation__preloader-icon"></div>
    </div>
  </div>
</template>

<script src="./ActivateConfirmation.js"></script>

<style src="./ActivateConfirmation.scss" lang="scss" scoped></style>
