<template>
  <div class="page">
    <Header />

    <div
      v-if="loading"
      class="page__preloader-container"
    >
      <Preloader />
    </div>

    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script src="./Page.js"></script>

<style src="./Page.scss" lang="scss" scoped></style>
