<template>
  <div class="verify-mfa-code">
    <div class="verify-mfa-code__title">Get the authenticator code from your app</div>

    <div class="verify-mfa-code__text">
      Open your authenticator app for the 6-digit code. It updates every 30 seconds.
    </div>

    <div class="verify-mfa-code__code-container">
      <code-verification
        :loading="loading"
        :error="errors.verification"
        :on-change="clearVerificationError"
        :on-focus="clearVerificationError"
        :on-verification="onVerifyConfirm"
      ></code-verification>
    </div>

    <div class="verify-mfa-code__link-holder">
      <div
        class="verify-mfa-code__link"
        @click="
          () => {
            toggleModal('help')
          }
        "
      >
        Help with your authenticator app
      </div>
    </div>

    <modal-authenticator-app-help
      v-if="modals.help"
      :on-close="
        () => {
          toggleModal('help')
        }
      "
    ></modal-authenticator-app-help>
  </div>
</template>

<script src="./VerifyMfaCode.js"></script>

<style src="./VerifyMfaCode.scss" lang="scss" scoped></style>
