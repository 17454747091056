<template>
  <Page>
    <div class="external-login-registration-success-msg">
      <img
        class="external-login-registration-success-msg__img"
        :src="images.superman"
        alt=""
      />

      <div class="external-login-registration-success-msg__title">Success!</div>

      <div class="external-login-registration-success-msg__text">
        Your account is now created.
        <br />
        You can now Sign In with your new credentials.
      </div>

      <Button
        auto-qa-id="sign-in-button"
        :loading="loading.button"
        :onClick="onSignInClick"
      >
        Sign in
      </Button>
    </div>
  </Page>
</template>

<script src="./ExternalLoginRegistrationSuccessMsg.js"></script>

<style src="./ExternalLoginRegistrationSuccessMsg.scss" lang="scss" scoped></style>
