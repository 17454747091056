<template>
  <Page>
    <div class="set-password">
      <div class="set-password__stepper-container">
        <Stepper
          :steps="steps"
          activeStep="password"
        />
      </div>

      <form
        ref="form"
        method="post"
        action="/Account/SetPassword"
      >
        <div class="set-password__title">Set a new password</div>

        <div class="set-password__input-container">
          <PasswordInput
            label="Enter your password"
            name="password"
            :hint="`
                            Password must be at least 8 characters with the mix of letters,
                            numbers & symbols.
                        `"
            required
            :value="password"
            :error="errors.password"
            auto-qa-id="password-input"
            :onChange="onInputChange"
            :onBlur="onInputBlur"
          />
        </div>

        <div class="set-password__input-container">
          <PasswordInput
            label="Re-enter your password"
            name="confirmPassword"
            required
            :value="confirmPassword"
            :error="errors.confirmPassword"
            auto-qa-id="password-confirmation-input"
            :onChange="onInputChange"
            :onBlur="onInputBlur"
          />
        </div>

        <div class="set-password__button-container">
          <Button
            v-show="!isLoading"
            auto-qa-id="confirm-button"
            :onClick="onSubmit"
          >
            Confirm
          </Button>

          <Preloader v-show="isLoading" />
        </div>

        <input
          type="hidden"
          name="Code"
          :value="code"
        />

        <input
          type="hidden"
          name="__RequestVerificationToken"
          :value="antiForgeryToken"
        />
      </form>
    </div>
  </Page>
</template>

<script src="./SetPassword.js"></script>

<style src="./SetPassword.scss" lang="scss" scoped></style>
