import Stepper from '@/uikit/Stepper/Stepper.vue'
import Link from '@/uikit/Link/Link.vue'
import Page from '@/components/Page/Page.vue'

const ChangePasswordConfirmation = {
  name: 'ChangePasswordConfirmation',

  components: {
    Stepper,
    Page,
    Link
  },

  data() {
    return {
      steps: [
        {
          id: 'email',
          label: 'Email',
          completed: true,
          disabled: false
        },
        {
          id: 'password',
          label: 'Password',
          completed: true,
          disabled: false
        },
        {
          id: 'completed',
          label: 'Completed',
          completed: true,
          disabled: false
        }
      ]
    }
  }
}

export default ChangePasswordConfirmation
