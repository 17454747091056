<template>
  <div class="download-authenticator-app">
    <div
      v-if="loading.component"
      class="download-authenticator-app__preloader-container"
    >
      <Preloader />
    </div>

    <template v-else>
      <div class="download-authenticator-app__title">
        Download an authenticator app on your phone
      </div>
      <div class="download-authenticator-app__text">
        Download any
        <span class="download-authenticator-app__bold-text">authenticator app</span>
        from Google Play Store or Apple App Store.
      </div>
      <div class="download-authenticator-app__text">
        An authenticator app generates a one time code that changes every 30 seconds.
      </div>
      <!-- prettier-ignore -->
      <div class="download-authenticator-app__text">
        We can recommend
        <span class="download-authenticator-app__bold-text">Google Authenticator</span>.
      </div>

      <div class="download-authenticator-app__apps-container">
        <div class="download-authenticator-app__apps-item">
          <div class="download-authenticator-app__app-icon-holder">
            <a
              href="https://play.google.com/store/apps"
              target="_blank"
            >
              <img
                :src="images.googlePlay"
                alt=""
              />
            </a>
          </div>
          <div class="download-authenticator-app__qr-code-container">
            <div class="download-authenticator-app__qr-code-label">Scan with camera:</div>
            <img
              :src="images.googlePlayQr"
              alt=""
            />
          </div>
        </div>
        <div class="download-authenticator-app__apps-item">
          <div class="download-authenticator-app__app-icon-holder">
            <a
              href="https://apps.apple.com"
              target="_blank"
            >
              <img
                :src="images.appStore"
                alt=""
              />
            </a>
          </div>
          <div class="download-authenticator-app__qr-code-container">
            <div class="download-authenticator-app__qr-code-label">Scan with camera:</div>
            <img
              :src="images.appStoreQr"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="download-authenticator-app__buttons-container">
        <div class="download-authenticator-app__button-holder">
          <Button
            variant="outlined"
            :onClick="onBackClick"
          >
            Back
          </Button>
        </div>
        <div class="download-authenticator-app__button-holder">
          <Button :onClick="onNextClick">Next</Button>
        </div>
      </div>
    </template>
  </div>
</template>

<script src="./DownloadAuthenticatorApp.js"></script>

<style src="./DownloadAuthenticatorApp.scss" lang="scss" scoped></style>
