import Icon from '@/uikit/Icon/Icon.vue'

export default {
  props: {
    message: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      default: 'primary'
    },
    autoHideDuration: {
      type: Number,
      default: null
    },
    autoQaId: {
      type: String,
      default: 'notification'
    },
    onClose: {
      type: Function,
      required: true
    }
  },

  components: {
    Icon
  },

  mounted() {
    if (this.autoHideDuration) {
      setTimeout(this.onClose, this.autoHideDuration)
    }
  }
}
