import Button from '@/uikit/Button/Button.vue'
import Page from '@/components/Page/Page.vue'

const SetPasswordError = {
  name: 'SetPasswordError',

  components: {
    Button,
    Page
  },

  methods: {
    onClick() {
      window.location.href = '/account/forgotpassword'
    }
  }
}

export default SetPasswordError
