<template>
  <div
    v-if="data && data.LabelParsed"
    class="cloud-storage"
  >
    <div class="cloud-storage__title">
      {{ data.Name }}
    </div>
    <div class="cloud-storage__description-container">
      <div class="cloud-storage__description">
        {{ data.LabelParsed.description }}
      </div>
      <img
        class="cloud-storage__image"
        :src="images.cloudStorage"
        alt=""
      />
    </div>
    <div class="cloud-storage__options">
      <div
        v-for="(option, index) in data.LabelParsed.options"
        :key="index"
        :class="[
          'cloud-storage__option',
          {
            'cloud-storage__option_state_disabled':
              data.HasConsentForPreviousVersion && !option.value,
            'cloud-storage__option_state_active': option.value === selectedOption
          }
        ]"
        @click="
          () => {
            onOptionClick(option.value)
          }
        "
      >
        <div class="cloud-storage__option-content">
          <div class="cloud-storage__option-title">
            {{ option.title }}

            <template v-if="option.value">
              *
            </template>

            <div
              v-if="option.value"
              class="cloud-storage__recommended-badge"
            >
              Recommended
            </div>
          </div>
          <div class="cloud-storage__option-list">
            <div
              v-for="(listItem, index) in option.list"
              :key="index"
              class="cloud-storage__option-list-item"
            >
              {{ listItem }}
            </div>
          </div>

          <div
            v-if="option.value"
            class="cloud-storage__asterisk"
          >
            * Cloud storage is currently available only for customers with a
            <br />
            TRIOS scanner and TRIOS license
          </div>
        </div>
        <div class="cloud-storage__radio-container">
          <Radio
            :value="option.value"
            :checked="option.value === selectedOption"
            :auto-qa-id="`cloud-storage-option-${index}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./CloudStorage.js"></script>

<style src="./CloudStorage.scss" lang="scss" scoped></style>
