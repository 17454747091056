<template>
  <Page>
    <div class="external-login-verification">
      <registration-verify-with-short-code
        :user-id="userId"
        :email="email"
      ></registration-verify-with-short-code>
    </div>
  </Page>
</template>

<script src="./ExternalLoginVerification.js"></script>

<style src="./ExternalLoginVerification.scss" lang="scss" scoped></style>
