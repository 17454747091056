const ActivateConfirmation = {
  name: 'ActivateConfirmation',

  props: {
    redirectUri: {
      type: String,
      required: true
    }
  },

  mounted() {
    if (this.redirectUri) {
      setTimeout(() => {
        window.location.replace(this.redirectUri)
      }, 500)
    }
  }
}

export default ActivateConfirmation
