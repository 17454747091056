<template>
  <Page>
    <div class="company-selection">
      <div
        v-if="defaultCompanyId"
        class="company-selection__title"
      >
        You are entering as a member of
      </div>
      <div
        v-else
        class="company-selection__title"
      >
        Choose your primary company
        <br />
        <div class="company-selection__description">
          When opening any of the 3Shape software applications, your primary company will be
          automatically selected.
        </div>
      </div>
      <div class="company-selection__container">
        <form
          ref="form"
          method="post"
          :action="action"
        >
          <div v-if="defaultCompanyId">
            <Dropdown
              :items="companiesDataWithGroups"
              label="Company"
              name="SelectedCompanyId"
              :value="company"
              auto-qa-id="company-selection-dropdown"
              :onChange="onChange"
            ></Dropdown>
          </div>
          <div v-else>
            <Dropdown
              :items="companiesData"
              label="Company"
              name="SelectedCompanyId"
              :value="company.value"
              autosuggest
              defaultInput="Select company"
              auto-qa-id="company-selection-dropdown"
              :onChange="onChange"
            />
          </div>

          <div class="company-selection__text-container">
            <div class="company-selection__text">
              Your permissions on each platform will depend on what access has been assigned to your
              company and the permissions assigned to you as a member of that organization.
            </div>
          </div>
          <div class="company-selection__button-container">
            <Button
              variant="outlined"
              :loading="loading.button"
              :disabled="!company"
              auto-qa-id="company-selection-continue-button"
              :onClick="submitForm"
            >
              Continue
            </Button>
          </div>

          <input
            v-if="userCode"
            type="hidden"
            name="UserCode"
            :value="userCode"
          />

          <input
            type="hidden"
            name="externalCompany"
            :value="external"
          />

          <input
            v-if="returnUrl"
            type="hidden"
            name="ReturnUrl"
            :value="returnUrl"
          />

          <input
            type="hidden"
            name="__RequestVerificationToken"
            :value="antiForgeryToken"
          />
        </form>
      </div>
    </div>
  </Page>
</template>

<script src="./CompanySelection.js"></script>

<style src="./CompanySelection.scss" lang="scss" scoped></style>
