import Api from '@/api'

export const validateField = (value, validators) => {
  if (!validators) {
    return ''
  }

  if (typeof validators !== 'function') {
    let fieldError = ''

    /* eslint-disable-next-line */
    for (const validator of validators) {
      const error = validator(value)

      if (error) {
        fieldError = error

        break
      }
    }

    return fieldError
  }

  return validators(value)
}

export const checkLowercase = (value) => /[a-z]/.test(value)

export const checkUppercase = (value) => /[A-Z]/.test(value)

export const checkNumber = (value) => /\d/.test(value)

export const checkSpecialSymbol = (value) => /[!"#$%&'()*+\-./:;<=>?@[\]|^_`{}~]/.test(value)

export const validateRequired = (value) => {
  if (!value) {
    return 'This field is required'
  }

  return ''
}

export const validateLength = (value, minLength, maxLength) => {
  if (value) {
    if (minLength && value.length < minLength) {
      return `This field must contain minimum ${minLength} characters`
    }

    if (maxLength && value.length > maxLength) {
      return `This field must contain maximum ${maxLength} characters`
    }
  }

  return ''
}

export const validateName = (value) => {
  if (value) {
    const regex = /^[^(?!\s*$),.0-9'\-+][a-zA-Z\s\p{L},.'-]*$/gu

    if (!regex.test(value)) {
      return 'Name cannot be empty or contain special characters. Please insert a valid name.'
    }
  }

  return ''
}

export const validateEmail = (value) => {
  if (value) {
    const regex = /^\w+(?:[+.\-\\']\w+)*@\w+(?:[.-]\w+)*(?:\.[a-zA-Z]{2,})+$/

    if (!regex.test(value)) {
      return 'Please insert correct email address'
    }
  }

  return ''
}

export const validateEmailAvailability = (value) =>
  new Promise(async (resolve) => {
    if (value) {
      try {
        await Api.checkEmail(value)

        resolve('')
      } catch (e) {
        resolve('Email is already in use')
      }
    }

    resolve('')
  })

export const validatePasswordComplexity = (value) => {
  if (value) {
    const requiredLevelOfComplexity = 3
    let complexity = 0

    const levels = [checkLowercase, checkUppercase, checkNumber, checkSpecialSymbol]

    levels.forEach((level) => {
      if (level(value)) {
        complexity += 1
      }
    })

    if (complexity < requiredLevelOfComplexity) {
      return 'The password you typed does not meet the password complexity requirements'
    }
  }

  return ''
}

export const validateCaptchaTokenExists = (value) => {
  if (!value) {
    return 'Captcha validation is missing'
  }

  return ''
}

export const validateCaptchaTokenExpired = (value) => {
  if (!value) {
    return 'Captcha validation expired, please re-enter captcha'
  }

  return ''
}
