import { onMounted } from 'vue'

export default {
  name: 'SendActivationEmail',

  props: {
    id: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },

  setup(props) {
    onMounted(() => {
      window.location.href = `/registration?userId=${props.id}&email=${props.email}`
    })

    return {}
  }
}
