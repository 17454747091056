import config from '@/api/config'

const Captcha = {
  name: 'Captcha',

  props: {
    mtCaptchaTestKey: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    onVerificationChanged: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      captchaId: 'register-captcha'
    }
  },

  beforeMount() {
    this.setCaptchaConfig()
    this.activateCaptcha()
  },

  methods: {
    verificationChangedCallback() {
      this.onVerificationChanged(window.mtcaptcha.getVerifiedToken())
    },

    setCaptchaConfig() {
      window.mtcaptchaConfig = {
        sitekey: 'MTPublic-W1SNat4o0',
        widgetSize: 'standard',
        theme: 'chrome',
        render: 'explicit',
        renderQueue: [],
        loadAnimation: 'false',
        enableTestMode: this.mtCaptchaTestKey,
        'verified-callback': this.verificationChangedCallback,
        'verifyexpired-callback': this.verificationChangedCallback
      }

      window.mtcaptchaConfig.renderQueue.push(this.captchaId)
    },

    activateCaptcha() {
      const mtService = document.createElement('script')
      mtService.async = true
      mtService.src = config.mtCaptcha.service1
      ;(
        document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
      ).appendChild(mtService)
      const mtService2 = document.createElement('script')
      mtService2.async = true
      mtService2.src = config.mtCaptcha.service2
      ;(
        document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
      ).appendChild(mtService2)
    }
  }
}

export default Captcha
