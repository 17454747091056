import { CodeVerification, Preloader } from 'account-ui-components'
import { ref, reactive } from 'vue'
import Api from '@/api'
import supermanIcon from '@/assets/img/superman.svg'
import Button from '@/uikit/Button/Button.vue'
import Page from '@/components/Page/Page.vue'

const VerifyTotpCode = {
  name: 'VerifyTotpCode',

  components: {
    Button,
    CodeVerification,
    Preloader,
    Page
  },

  setup() {
    const loading = reactive({
      component: false,
      confirm: false,
      resend: false
    })
    const images = {
      superman: supermanIcon
    }
    const isCodeConfirmed = ref(false)

    const onVerifyConfirm = (totpCode) =>
      new Promise(async (resolve, reject) => {
        loading.confirm = true

        try {
          const url = new URL(window.location.href)
          const params = new URLSearchParams(url.search)
          const returnUrl = params.get('returnUrl')

          await Api.processTotpCode(totpCode)

          isCodeConfirmed.value = true

          if (returnUrl) {
            window.location.href = returnUrl
          }

          resolve()
        } catch (err) {
          reject(err)
        }

        loading.confirm = false
      })

    return {
      images,
      loading,
      onVerifyConfirm,
      isCodeConfirmed
    }
  }
}

export default VerifyTotpCode
