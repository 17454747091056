import { Modal, Button } from 'account-ui-components'
import smartphoneHelpIcon from '@/assets/img/smartphone-help.svg'

export default {
  name: 'ModalAuthenticatorAppHelp',

  props: {
    onClose: {
      type: Function,
      required: true
    }
  },

  components: {
    Modal,
    Button
  },

  setup() {
    const images = {
      smartphoneHelp: smartphoneHelpIcon
    }

    return {
      images
    }
  }
}
