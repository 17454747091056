<template>
  <div class="password-input">
    <Input
      :value="value"
      :type="showPassword ? 'text' : 'password'"
      :name="name"
      :label="label ? label : 'Password'"
      :autocomplete="disableAutocomplete ? 'new-password' : ''"
      :required="required"
      :error="error"
      :hint="hint"
      :auto-qa-id="autoQaId"
      :onChange="onChange"
      :onFocus="onFocus"
      :onBlur="onBlur"
      :onKeyPress="onKeyPress"
      :onEnterKeyPress="onEnterKeyPress"
    />

    <div
      class="password-input__show-button"
      :class="{
        'password-input__show-button_state_active': showPassword
      }"
      :title="showPassword ? 'Hide' : 'Show'"
      @click="togglePasswordVisibility"
    >
      <svg
        class="password-input__show-button-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 12.942"
      >
        <g transform="translate(.5 .5)">
          <path
            d="M.013 5.944H0l.007.011-.007.01h.013c.368.585 8.419 13.075 17.972 0H18l-.008-.011.008-.01h-.015C14.729 1.488 11.646 0 8.984 0 3.835 0 .255 5.558.013 5.944z"
            class="color1"
            fill="none"
            stroke="#5F5F64"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle
            cx="2.676"
            cy="2.676"
            r="2.676"
            transform="translate(6.422 3.211)"
            class="color2"
            fill="#5F5F64"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script src="./PasswordInput.js"></script>

<style src="./PasswordInput.scss" lang="scss" scoped></style>
