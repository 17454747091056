<template>
  <Page>
    <div class="change-password-confirmation">
      <div class="change-password-confirmation__stepper-container">
        <Stepper
          :steps="steps"
          activeStep="completed"
        />
      </div>

      <div class="change-password-confirmation__title">Password Change Successful</div>
      <div class="change-password-confirmation__text">
        Your password has been changed. Please
        <Link
          auto-qa-id="sign-in-link"
          href="/account/login"
        >
          click here to sign in
        </Link>
      </div>
    </div>
  </Page>
</template>

<script src="./ChangePasswordConfirmation.js"></script>

<style src="./ChangePasswordConfirmation.scss" lang="scss" scoped></style>
