const Link = {
  name: 'Link',

  props: {
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: ''
    },
    autoQaId: {
      type: String,
      default: 'link'
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}

export default Link
