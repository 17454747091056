import Input from '@/uikit/Input/Input.vue'

const PasswordInput = {
  name: 'PasswordInput',

  components: {
    Input
  },

  props: {
    value: {
      type: String
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: null
    },
    autoQaId: {
      type: String,
      default: 'password-input'
    },
    onChange: {
      type: Function,
      required: true
    },
    onFocus: {
      type: Function,
      default: () => {}
    },
    onBlur: {
      type: Function,
      default: () => {}
    },
    onKeyPress: {
      type: Function,
      default: () => {}
    },
    onEnterKeyPress: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      showPassword: false
    }
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    }
  }
}

export default PasswordInput
