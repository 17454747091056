import { ref, reactive, onMounted } from 'vue'
import { Preloader } from 'account-ui-components'
import 'vue-pdf-embed/dist/style/index.css'
import 'vue-pdf-embed/dist/style/annotationLayer.css'
import 'vue-pdf-embed/dist/style/textLayer.css'

export default {
  name: 'PdfViewer',

  components: {
    Preloader
  },

  props: {
    pdfUrl: String
  },

  setup() {
    const loading = reactive({
      library: true,
      document: true
    })
    const vuePdfEmbed = ref(null)

    onMounted(async () => {
      if (!vuePdfEmbed.value) {
        const module = await import('vue-pdf-embed')
        vuePdfEmbed.value = module.default
      }

      loading.library = false
    })

    const handleDocumentLoad = () => {
      loading.document = false
    }

    return {
      loading,
      vuePdfEmbed,
      handleDocumentLoad
    }
  }
}
