<template>
  <Page>
    <div class="verify-local-password">
      <div class="verify-local-password__title">Enable single sign-on</div>

      <form
        ref="form"
        method="post"
      >
        <div
          v-if="isMicrosoftPreselected"
          class="verify-local-password__text"
        >
          It is required that your IT provider already have integrated SSO into your IT
          infrastructure. If SSO is not enabled for you after completing the next steps, contact
          your IT provider who can be guided via
          <Link
            :href="SSODocumentationLink"
            target="_blank"
          >
            this link
          </Link>
          .
        </div>

        <template v-if="isLoginProviderPreselected">
          <input
            type="hidden"
            name="VerifyPasswordForm.AuthenticationScheme"
            :value="fields.authenticationScheme"
          />
        </template>
        <div
          v-else
          class="verify-local-password__input-container"
        >
          <Dropdown
            label="Login Provider"
            name="VerifyPasswordForm.AuthenticationScheme"
            required
            :items="options"
            :value="fields.authenticationScheme"
            :error="errors.authenticationScheme"
            :disabled="loginProvider"
            auto-qa-id="login-provider-dropdown"
            :onChange="onDropdownChange"
          />
        </div>

        <div class="verify-local-password__text">
          Enter your 3Shape Account password before you will be re-directed to your single sign-on.
        </div>

        <div class="verify-local-password__input-container">
          <PasswordInput
            name="VerifyPasswordForm.Password"
            hint="Your current 3Shape Account password"
            required
            disable-autocomplete
            :value="fields.password"
            :error="errors.password"
            auto-qa-id="password-input"
            :onChange="onInputChange"
          />
        </div>

        <div class="verify-local-password__button-container">
          <Button
            :loading="loading.button"
            :disabled="!fields.authenticationScheme || !fields.password"
            auto-qa-id="continue-button"
            :onClick="onButtonClick"
          >
            Continue
          </Button>
        </div>

        <input
          type="hidden"
          name="__RequestVerificationToken"
          :value="antiForgeryToken"
        />
      </form>
    </div>
  </Page>
</template>

<script src="./VerifyLocalPassword.js"></script>

<style src="./VerifyLocalPassword.scss" lang="scss" scoped></style>
