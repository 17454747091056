import { Modal, Button } from 'account-ui-components'

export default {
  name: 'ModalEmailAlreadyInUse',

  props: {
    onClose: {
      type: Function,
      required: true
    }
  },

  components: {
    Modal,
    Button
  }
}
