<template>
  <Page>
    <div class="device-result-msg">
      <template v-if="status === 'success'">
        <img
          class="device-result-msg__img"
          :src="images.supermanIcon"
          alt=""
        />
        <div class="device-result-msg__title">Success!</div>
        <div class="device-result-msg__text">
          This device has been successfully authorized.
          <br />
          You can now close this page.
        </div>
      </template>
      <template v-else>
        <img
          class="device-result-msg__img device-result-msg__img_type_failure"
          :src="images.toothIcon"
          alt=""
        />
        <div class="device-result-msg__title">Sorry, there was an error</div>
        <div class="device-result-msg__text">Something went wrong. Please try again.</div>
        <div class="device-result-msg__button-container">
          <Button
            auto-qa-id="go-back-button"
            :onClick="onBackClick"
          >
            Go Back
          </Button>
        </div>
      </template>
    </div>
  </Page>
</template>

<script src="./DeviceResultMsg.js"></script>

<style src="./DeviceResultMsg.scss" lang="scss" scoped></style>
