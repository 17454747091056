<template>
  <Page>
    <div class="set-password-error">
      <div class="set-password-error__container">
        <div class="set-password-error__icon-container">
          <svg
            class="set-password-error__icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 106.297 113.115"
          >
            <g>
              <path
                d="M91.675 76.93H50.929l-24.257 32.185V76.93h-12.05A10.429 10.429 0 014 66.783V14.147A10.429 10.429 0 0114.622 4h76.894C97.541 4 102.3 8.6 102.3 14.147v52.636A10.429 10.429 0 0191.675 76.93z"
                fill="none"
                stroke="#828289"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              />
            </g>
          </svg>
        </div>

        <div class="set-password-error__text-container">
          <div class="set-password-error__title">Reset password error</div>

          <div class="set-password-error__text">
            Unable to reset password because the reset link is not valid anymore.
            <br />
            Please generate a new password by clicking on the Forgot password button.
          </div>

          <Button
            auto-qa-id="forgot-password-button"
            :onClick="onClick"
          >
            Forgot password
          </Button>
        </div>
      </div>
    </div>
  </Page>
</template>

<script src="./SetPasswordError.js"></script>

<style src="./SetPasswordError.scss" lang="scss" scoped></style>
