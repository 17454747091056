import { reactive } from 'vue'
import Button from '@/uikit/Button/Button.vue'
import Page from '@/components/Page/Page.vue'
import supermanIcon from '@/assets/img/superman.svg'

export default {
  name: 'ExternalLoginRegistrationSuccessMsg',

  props: {
    redirectUrl: {
      type: String,
      required: true
    }
  },

  components: {
    Page,
    Button
  },

  setup(props) {
    const images = {
      superman: supermanIcon
    }
    const loading = reactive({
      button: false
    })

    const onSignInClick = () => {
      loading.button = true

      window.location.href = props.redirectUrl
    }

    return {
      images,
      loading,
      onSignInClick
    }
  }
}
