<template>
  <Page>
    <div class="logout-confirmation">
      <div class="logout-confirmation__title-container">
        <Title>You have been signed out</Title>
      </div>

      <div class="logout-confirmation__text">Please sign in again</div>

      <div class="logout-confirmation__actions-container">
        <Button
          auto-qa-id="sign-in-button"
          :onClick="onConfirmClick"
        >
          Sign in
        </Button>
      </div>
    </div>
  </Page>
</template>

<script src="./LogoutConfirmation.js"></script>

<style src="./LogoutConfirmation.scss" lang="scss" scoped></style>
