import Page from '@/components/Page/Page.vue'
import RegistrationVerifyWithShortCode from '@/components/RegistrationVerifyWithShortCode/RegistrationVerifyWithShortCode.vue'

export default {
  name: 'ExternalLoginVerification',

  props: {
    userId: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },

  components: {
    Page,
    RegistrationVerifyWithShortCode
  }
}
