import Button from '@/uikit/Button/Button.vue'

const NotFound = {
  name: 'NotFound',

  components: {
    Button
  },

  methods: {
    onClick() {
      window.history.back()
    }
  }
}

export default NotFound
