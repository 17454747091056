import { reactive, onMounted } from 'vue'
import { Preloader, Button } from 'account-ui-components'
import { preloadImages } from '@/helpers'
import linkAccountQrIcon from '@/assets/img/link-account-qr.svg'
import authAppIcon from '@/assets/img/auth-app-animation.gif'

export default {
  name: 'LinkAccountToAuthenticatorApp',

  props: {
    qrCodeDataUri: {
      type: String,
      required: true
    },
    multiFactorKey: {
      type: String,
      required: true
    },
    onBackClick: {
      type: Function,
      required: true
    },
    onNextClick: {
      type: Function,
      required: true
    }
  },

  components: {
    Preloader,
    Button
  },

  setup() {
    const loading = reactive({
      component: true
    })
    const images = {
      linkAccountQr: linkAccountQrIcon,
      smartphone: authAppIcon
    }

    onMounted(async () => {
      await preloadImages(Object.values(images))

      loading.component = false
    })

    return {
      loading,
      images
    }
  }
}
