import { Title, Button } from 'account-ui-components'
import Page from '@/components/Page/Page.vue'

export default {
  name: 'LogoutConfirmation',
  props: {
    redirectUrl: {
      type: String,
      required: true
    },
    hasAutoRedirect: {
      type: Boolean
    }
  },
  components: {
    Page,
    Title,
    Button
  },

  setup(props) {
    if (props.hasAutoRedirect && props.redirectUrl) {
      window.location.assign(props.redirectUrl)
    }

    const onConfirmClick = () => {
      window.location.assign(props.redirectUrl || '/account/login')
    }

    return {
      onConfirmClick
    }
  }
}
