import { ref, reactive, computed } from 'vue'
import { PasswordInput, Dropdown, Button, Link } from 'account-ui-components'
import Page from '@/components/Page/Page.vue'

export default {
  name: 'VerifyLocalPassword',

  props: {
    authenticationSchemes: {
      type: Array,
      required: true
    },
    antiForgeryToken: {
      type: String,
      required: true
    }
  },

  components: {
    PasswordInput,
    Dropdown,
    Button,
    Link,
    Page
  },

  setup(props) {
    const urlParams = new URLSearchParams(window.location.search)
    const loginProvider = urlParams.get('loginProvider') || urlParams.get('loginprovider')
    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')
    const antiForgeryToken = antiForgeryField && antiForgeryField.value
    const backendErrorElement = document.querySelector('#backend-error')
    const backendErrorMessage = backendErrorElement && backendErrorElement.innerText
    const form = ref(null)
    const loading = reactive({
      button: false
    })
    const fields = reactive({
      authenticationScheme: 'microsoft',
      password: null
    })
    const errors = reactive({
      authenticationScheme: '',
      password: ''
    })
    const isMicrosoftPreselected = computed(
      () => loginProvider && loginProvider.toLowerCase() === 'microsoft'
    )
    const isGooglePreselected = computed(
      () => loginProvider && loginProvider.toLowerCase() === 'google'
    )
    const isLoginProviderPreselected = computed(
      () => isMicrosoftPreselected.value || isGooglePreselected.value
    )
    const options =
      props.authenticationSchemes &&
      props.authenticationSchemes.map(({ Text, Value }) => ({
        value: Value,
        label: Text
      }))
    const SSODocumentationLink =
      'https://support.3shape.com/products-3shape-unite-3shape-account-how-to/enabling-microsoft-single-single-on-for-3shape-account#enabling-single-sign-on-for-existing-users-3'

    if (backendErrorMessage) {
      fields.authenticationScheme = sessionStorage.getItem('authenticationScheme')

      errors.password = backendErrorMessage
    } else if (loginProvider) {
      const optionToAutofill = options.find(
        (option) => loginProvider.toLowerCase() === option.value.toLowerCase()
      )

      if (optionToAutofill) {
        fields.authenticationScheme = optionToAutofill.value
      }
    }

    const clearErrorField = (name) => {
      errors[name] = ''
    }

    const onDropdownChange = ({ value }) => {
      fields.authenticationScheme = value
    }

    const onInputChange = (event) => {
      const { value } = event.target

      fields.password = value

      if (errors.password) {
        clearErrorField('password')
      }
    }

    const onButtonClick = () => {
      loading.button = true

      sessionStorage.setItem('authenticationScheme', fields.authenticationScheme)

      form.value.submit()
    }

    return {
      antiForgeryToken,
      loginProvider,
      loading,
      form,
      isMicrosoftPreselected,
      isGooglePreselected,
      isLoginProviderPreselected,
      options,
      fields,
      errors,
      SSODocumentationLink,
      onDropdownChange,
      onInputChange,
      onButtonClick
    }
  }
}
