import { Button } from 'account-ui-components'
import {
  validateField,
  validateLength,
  validatePasswordComplexity,
  validateRequired
} from '@/helpers/validators'
import Stepper from '@/uikit/Stepper/Stepper.vue'
import PasswordInput from '@/uikit/PasswordInput/PasswordInput.vue'
import Preloader from '@/uikit/Preloader/Preloader.vue'
import Page from '@/components/Page/Page.vue'

const SetPassword = {
  name: 'SetPassword',

  components: {
    Stepper,
    PasswordInput,
    Button,
    Preloader,
    Page
  },

  data() {
    return {
      steps: [
        {
          id: 'email',
          label: 'Email',
          completed: true,
          disabled: false
        },
        {
          id: 'password',
          label: 'Password',
          completed: false,
          disabled: false
        },
        {
          id: 'completed',
          label: 'Completed',
          completed: false,
          disabled: true
        }
      ],
      form: null,
      antiForgeryToken: null,
      code: null,
      isLoading: false,
      password: null,
      confirmPassword: null,
      errors: {
        password: '',
        confirmPassword: ''
      },
      validations: {
        password: [
          validateRequired,
          (value) => validateLength(value, 8, 50),
          validatePasswordComplexity
        ],
        confirmPassword: [
          validateRequired,
          (value) => {
            const passwordMatch = value === this.password

            return passwordMatch ? '' : 'Password fields do not match'
          }
        ]
      }
    }
  },

  created() {
    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')

    if (antiForgeryField) {
      this.antiForgeryToken = antiForgeryField.value
    }

    const urlParams = new URLSearchParams(window.location.search)

    this.code = urlParams.get('code')
  },

  methods: {
    onInputChange(event) {
      const { value, name } = event.target

      this[name] = value

      this.errors = {
        ...this.errors,
        password: '',
        confirmPassword: ''
      }
    },

    onInputBlur(event) {
      const { value, name } = event.target

      if (this[name] !== null) {
        const error = validateField(value, this.validations[name])

        if (error) {
          this.errors[name] = error
        }
      }
    },

    validateFields() {
      let isError = false
      const fields = ['password', 'confirmPassword']

      fields.forEach((field) => {
        const error = validateField(this[field], this.validations[field])

        if (error) {
          this.errors[field] = error

          isError = true
        }
      })

      return isError
    },

    onSubmit(event) {
      const isError = this.validateFields()

      if (!isError) {
        this.isLoading = true

        this.$refs.form.submit()
      }

      event.preventDefault()
    }
  }
}

export default SetPassword
