<template>
  <Page>
    <div class="forgot-password-confirmation">
      <div
        v-if="loading.component"
        class="forgot-password-confirmation__preloader-container"
      >
        <Preloader />
      </div>

      <template v-else>
        <div class="forgot-password-confirmation__title">Password recovery</div>

        <div class="forgot-password-confirmation__text">
          Please enter the code we have sent to the email account
          <div class="forgot-password-confirmation__highlighted-text">
            {{ email }}
          </div>
          , or click the link in the email.
        </div>

        <code-verification
          :loading="loading"
          :error="errors.code"
          :on-change="clearCodeVerificationError"
          :on-focus="clearCodeVerificationError"
          :on-verification="onVerifyConfirm"
        ></code-verification>

        <div class="forgot-password-confirmation__no-access-holder">
          No access to email
          <div
            class="forgot-password-confirmation__question-icon"
            v-tooltip="{
              content: `
                  If you cannot access this code, please
                  contact 3Shape Support for assistance.
              `,
              offset: [8, 8]
            }"
          >
            <img :src="images.questionMark" />
          </div>
        </div>
      </template>
    </div>
  </Page>
</template>

<script src="./ForgotPasswordConfirmation.js"></script>

<style src="./ForgotPasswordConfirmation.scss" lang="scss" scoped></style>
