<template>
  <Page>
    <div class="email-change-confirmation">
      <div class="email-change-confirmation__title">Email is changed</div>
      <div class="email-change-confirmation__text">
        Your email is now changed to {{ email }}, and you can now use it to login.
      </div>
      <a href="/account/login">Click here to return to the login page</a>
    </div>
  </Page>
</template>

<script src="./EmailChangeConfirmation.js"></script>

<style src="./EmailChangeConfirmation.scss" lang="scss" scoped></style>
