import { Button, Link } from 'account-ui-components'
import userMessageIcon from '@/assets/img/user-message.svg'

export default {
  name: 'ConsentsAcceptancePending',

  props: {
    userName: {
      type: String,
      default: null
    },
    softwareName: {
      type: String,
      default: null
    },
    onLogout: {
      type: Function,
      required: true
    }
  },

  components: {
    Button,
    Link
  },

  setup() {
    const images = {
      userMessage: userMessageIcon
    }

    return {
      images
    }
  }
}
