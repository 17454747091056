<template>
  <Page>
    <div class="logout">
      <div class="logout__title-container">
        <Title>Sign out</Title>
      </div>

      <div class="logout__text">Would you like to Sign out of 3Shape Account?</div>

      <div class="logout__actions-container">
        <form
          ref="form"
          method="post"
          action="/account/logout"
        >
          <input
            type="hidden"
            name="__RequestVerificationToken"
            :value="antiForgeryToken"
          />
          <input
            type="hidden"
            name="logoutId"
            :value="logoutId"
          />
          <Button
            auto-qa-id="sign-out-button"
            :onClick="onSubmit"
          >
            Sign Out
          </Button>
        </form>
      </div>
    </div>
  </Page>
</template>

<script src="./Logout.js"></script>

<style src="./Logout.scss" lang="scss" scoped></style>
