<template>
  <a
    class="link"
    :href="href"
    :target="target"
    :data-auto-qa-id="autoQaId"
    @click="onClick"
  >
    <slot />
  </a>
</template>

<script src="./Link.js"></script>

<style src="./Link.scss" lang="scss" scoped></style>
