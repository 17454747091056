import Page from '@/components/Page/Page.vue'

const EmailChangeConfirmation = {
  name: 'EmailChangeConfirmation',

  props: {
    email: {
      type: String,
      required: true
    }
  },

  components: {
    Page
  }
}

export default EmailChangeConfirmation
