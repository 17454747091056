const config = {
  hosts: {
    development: {
      profile: 'http://localhost:8080',
      consentsApi: 'http://localhost:10333'
    },
    test: {
      profile: 'https://test-profile.identity.3shape.com',
      consentsApi: 'https://test-consents.3shape.com'
    },
    staging: {
      profile: 'https://staging-profile.identity.3shape.com',
      consentsApi: 'https://staging-consents.3shape.com'
    },
    production: {
      profile: 'https://profile.identity.3shape.com',
      consentsApi: 'https://consents.3shape.com'
    }
  },
  mtCaptcha: {
    service1: 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js',
    service2: 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js'
  }
}

export default config
