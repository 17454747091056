<template>
  <div class="link-account-to-authenticator-app">
    <div v-if="loading.component">
      <Preloader />
    </div>

    <template v-else>
      <div class="link-account-to-authenticator-app__title">Add 3Shape Account in the app</div>
      <div class="link-account-to-authenticator-app__text">
        In the app, find the add or scan QR code button.
        <br />
        Use the code below to link with 3Shape Account.
      </div>

      <div class="link-account-to-authenticator-app__code-container">
        <div>
          <div class="link-account-to-authenticator-app__code-label">Scan the QR code:</div>
          <div class="link-account-to-authenticator-app__qr-code-container">
            <img
              class="link-account-to-authenticator-app__qr-code"
              :src="qrCodeDataUri"
              alt=""
            />
          </div>
          <div class="link-account-to-authenticator-app__code-label">Or enter code:</div>
          <div class="link-account-to-authenticator-app__code">
            {{ multiFactorKey }}
          </div>
        </div>
        <div class="link-account-to-authenticator-app__phone-icon-container">
          <img
            class="link-account-to-authenticator-app__phone-icon"
            :src="images.smartphone"
            alt=""
          />
        </div>
      </div>

      <div class="link-account-to-authenticator-app__buttons-container">
        <div class="link-account-to-authenticator-app__button-holder">
          <Button
            variant="outlined"
            :onClick="onBackClick"
          >
            Back
          </Button>
        </div>
        <div class="link-account-to-authenticator-app__button-holder">
          <Button :onClick="onNextClick">Next</Button>
        </div>
      </div>
    </template>
  </div>
</template>

<script src="./LinkAccountToAuthenticatorApp.js"></script>

<style src="./LinkAccountToAuthenticatorApp.scss" lang="scss" scoped></style>
