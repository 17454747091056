import Preloader from '@/uikit/Preloader/Preloader.vue'

export default {
  name: 'Button',

  props: {
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    autoQaId: {
      type: String,
      default: 'button'
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },

  components: {
    Preloader
  }
}
