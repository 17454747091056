import config from '@/api/config'
import { getEnv } from '@/helpers'
import Button from '@/uikit/Button/Button.vue'
import Page from '@/components/Page/Page.vue'
import supermanIcon from '@/assets/img/superman.svg'
import toothIcon from '@/assets/img/tooth.svg'

export default {
  name: 'DeviceResultMsg',

  props: {
    status: {
      type: String,
      default: 'success'
    }
  },

  components: {
    Button,
    Page
  },

  setup() {
    const images = {
      supermanIcon,
      toothIcon
    }
    const env = getEnv()

    const onBackClick = () => {
      const { profile } = config.hosts[env]

      window.location.href = profile
    }

    return {
      images,
      onBackClick
    }
  }
}
