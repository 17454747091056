<template>
  <div class="button">
    <div
      v-show="loading"
      class="button__preloader-container"
    >
      <Preloader />
    </div>

    <button
      class="button__button"
      :class="{
        button_state_hidden: loading,
        button_state_disabled: disabled,
        button_theme_transparent: theme === 'transparent',
        button_type_fluid: fluid
      }"
      :data-auto-qa-id="autoQaId"
      :type="type"
      :value="value"
      :name="name"
      @click="onClick"
    >
      <slot />
    </button>
  </div>
</template>

<script src="./Button.js"></script>

<style src="./Button.scss" lang="scss" scoped></style>
