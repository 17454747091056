// eslint-disable-next-line
export const getEnv = () => {
  const { hostname } = window.location

  const isDevelopment = hostname === 'localhost'
  const isTest = hostname.indexOf('test') !== -1
  const isStaging = hostname.indexOf('staging') !== -1

  let env = 'production'

  if (isDevelopment) {
    env = 'development'
  } else if (isTest) {
    env = 'test'
  } else if (isStaging) {
    env = 'staging'
  }

  return env
}

export const preloadImages = (imageSources) => {
  const imagePromises = imageSources.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => resolve()
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`))
    })
  })
  return Promise.all(imagePromises)
}

export const parseCookie = (str) =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
