import { ref, computed } from 'vue'
import questionMarkIcon from '@/assets/img/questionmark.svg'
import googleIcon from '@/assets/img/google.svg'
import microsoftIcon from '@/assets/img/microsoft.svg'

export default {
  name: 'ExternalLogin',

  props: {
    providers: {
      type: Array,
      required: true
    },
    platform: {
      type: String,
      default: null
    }
  },

  setup() {
    const images = {
      questionMark: questionMarkIcon,
      google: googleIcon,
      microsoft: microsoftIcon
    }
    const antiForgeryToken = ref(null)
    const urlParams = new URLSearchParams(window.location.search)
    const returnUrl = urlParams.get('returnUrl') || urlParams.get('ReturnUrl')
    const action = computed(() => {
      let url = '/Account/ExternalLogin'

      if (returnUrl) {
        url = `${url}?returnUrl=${encodeURIComponent(returnUrl)}`
      }

      return url
    })

    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')

    if (antiForgeryField) {
      antiForgeryToken.value = antiForgeryField.value
    }

    return {
      images,
      action,
      antiForgeryToken
    }
  }
}
