<template>
  <div
    class="input"
    :class="{
      input_state_disabled: disabled,
      input_type_required: required
    }"
  >
    <label
      v-if="label"
      class="input__label"
      :for="id"
    >
      {{ label }}
    </label>

    <input
      ref="input"
      :data-auto-qa-id="autoQaId"
      :id="id"
      class="input__field"
      :value="value"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :tabindex="tabindex"
      :disabled="disabled"
      @input="onChange"
      @focus="onFocus"
      @blur="onBlur"
      @keyup="onKeyUp"
    />

    <div
      v-if="error"
      class="input__error"
    >
      {{ error }}
    </div>

    <div
      v-if="hint"
      class="input__hint"
    >
      {{ hint }}
    </div>
  </div>
</template>

<script src="./Input.js"></script>

<style src="./Input.scss" lang="scss" scoped></style>
