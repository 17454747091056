import { Button } from 'account-ui-components'
import { validateField, validateRequired, validateEmail } from '@/helpers/validators'
import Stepper from '@/uikit/Stepper/Stepper.vue'
import Input from '@/uikit/Input/Input.vue'
import Preloader from '@/uikit/Preloader/Preloader.vue'
import Page from '@/components/Page/Page.vue'

const ForgotPassword = {
  name: 'ForgotPassword',

  components: {
    Stepper,
    Input,
    Button,
    Preloader,
    Page
  },

  data() {
    return {
      form: null,
      steps: [
        {
          id: 'email',
          label: 'Email',
          completed: false,
          disabled: false
        },
        {
          id: 'password',
          label: 'Password',
          completed: false,
          disabled: true
        },
        {
          id: 'completed',
          label: 'Completed',
          completed: false,
          disabled: true
        }
      ],
      antiForgeryToken: null,
      email: null,
      errors: {
        email: ''
      },
      validations: {
        email: [validateRequired, validateEmail]
      },
      isLoading: false
    }
  },

  created() {
    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')

    if (antiForgeryField) {
      this.antiForgeryToken = antiForgeryField.value
    }

    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get('email')

    if (email) {
      this.email = email
    }
  },

  methods: {
    onInputChange(event) {
      const { value } = event.target

      this.email = value

      if (this.errors.email) {
        this.errors.email = ''
      }
    },

    onInputBlur() {
      if (this.email !== null) {
        this.validateEmailField()
      }
    },

    validateEmailField() {
      const error = validateField(this.email, this.validations.email)

      this.errors.email = error

      return error
    },

    onSubmit(event) {
      const isError = this.validateEmailField()

      if (!isError) {
        this.isLoading = true

        localStorage.setItem('email', this.email)

        this.$refs.form.submit()
      }

      event.preventDefault()
    },

    onKeyDown(event) {
      if (event.key === 'Enter') {
        this.onSubmit(event)
      }
    }
  }
}

export default ForgotPassword
