import { createApp } from 'vue'
import { vuetify } from 'account-ui-components'
import VTooltip from 'v-tooltip'
import '@/style/main.scss'
import 'account-ui-components/dist/style.css'
import 'v-tooltip/dist/v-tooltip.css'
import '@/assets/img/favicon.ico'
import ActivateConfirmation from '@/components/ActivateConfirmation/ActivateConfirmation.vue'
import ChangePasswordConfirmation from '@/components/ChangePasswordConfirmation/ChangePasswordConfirmation.vue'
import CompanySelection from '@/components/CompanySelection/CompanySelection.vue'
import Consents from '@/components/Consents/Consents.vue'
import DeviceResultMsg from '@/components/DeviceResultMsg/DeviceResultMsg.vue'
import EmailChangeConfirmation from '@/components/EmailChangeConfirmation/EmailChangeConfirmation.vue'
import Error from '@/components/Error/Error.vue'
import ExternalLoginRegistrationSuccessMsg from '@/components/ExternalLoginRegistrationSuccessMsg/ExternalLoginRegistrationSuccessMsg.vue'
import ExternalLoginVerification from '@/components/ExternalLoginVerification/ExternalLoginVerification.vue'
import ForgotPassword from '@/components/ForgotPassword/ForgotPassword.vue'
import ForgotPasswordConfirmation from '@/components/ForgotPasswordConfirmation/ForgotPasswordConfirmation.vue'
import Login from '@/components/Login/Login.vue'
import Logout from '@/components/Logout/Logout.vue'
import LogoutConfirmation from '@/components/LogoutConfirmation/LogoutConfirmation.vue'
import NotFound from '@/components/NotFound/NotFound.vue'
import Registration from '@/components/Registration/Registration.vue'
import SendActivationEmail from '@/components/SendActivationEmail/SendActivationEmail.vue'
import SetPassword from '@/components/SetPassword/SetPassword.vue'
import SetPasswordError from '@/components/SetPasswordError/SetPasswordError.vue'
import VerifyLocalPassword from '@/components/VerifyLocalPassword/VerifyLocalPassword.vue'
import ErrorInvalidCode from '@/components/ErrorInvalidCode/ErrorInvalidCode.vue'
import MultiFactorAuthentication from '@/components/MultiFactorAuthentication/MultiFactorAuthentication.vue'
import VerifyTotpCode from '@/components/VerifyTotpCode/VerifyTotpCode.vue'

const componentsMap = {
  '#activate-confirmation': {
    component: ActivateConfirmation,
    props: (node) => ({
      redirectUri: node.getAttribute('data-redirect-uri')
    })
  },
  '#change-password-confirmation': {
    component: ChangePasswordConfirmation
  },
  '#company-selection': {
    component: CompanySelection,
    props: (node) => ({
      action: node.getAttribute('data-action'),
      defaultCompanyId: node.getAttribute('data-default-company-id'),
      defaultCompanyName: node.getAttribute('data-default-company-name'),
      companies: JSON.parse(node.getAttribute('data-companies'))
    })
  },
  '#consents': {
    component: Consents,
    props: (node) => ({
      items: node.getAttribute('data-items'),
      softwareName: node.getAttribute('data-client-name'),
      userName: node.getAttribute('data-user-name')
    })
  },
  '#device-result-msg': {
    component: DeviceResultMsg,
    props: () => {
      const urlParams = new URLSearchParams(window.location.search)
      return { status: urlParams.get('status').toLowerCase() }
    }
  },
  '#email-change-confirmation': {
    component: EmailChangeConfirmation,
    props: (node) => ({ email: node.getAttribute('data-email') })
  },
  '#error': {
    component: Error,
    props: (node) => ({
      errorTitle: node.getAttribute('data-error-title'),
      errorDescription: node.getAttribute('data-error-description')
    })
  },
  '#external-login-registration-success-msg': {
    component: ExternalLoginRegistrationSuccessMsg,
    props: (node) => ({
      redirectUrl: node.getAttribute('data-redirect-url')
    })
  },
  '#external-login-verification': {
    component: ExternalLoginVerification,
    props: (node) => ({
      userId: node.getAttribute('data-user-id'),
      email: node.getAttribute('data-email')
    })
  },
  '#forgot-password': {
    component: ForgotPassword
  },
  '#forgot-password-confirmation': {
    component: ForgotPasswordConfirmation
  },
  '#login': {
    component: Login,
    props: (node) => ({
      prefilledUsername: node.getAttribute('data-username'),
      isHintLocked: /^true$/i.test(node.getAttribute('data-hint-locked')),
      mtCaptchaTestKey: node.getAttribute('mt-captcha-test-key'),
      providers: JSON.parse(node.getAttribute('data-providers')).map((item) => item.Name),
      isCaptchaActive: /^true$/i.test(node.getAttribute('data-is-captcha-active')),
      logoUri: node.getAttribute('data-logo-uri'),
      clientName: node.getAttribute('data-client-name')
    })
  },
  '#logout': {
    component: Logout,
    props: (node) => ({
      logoutId: node.getAttribute('data-logout-id'),
      antiForgeryToken: document.getElementsByName('__RequestVerificationToken')[0].value
    })
  },
  '#logout-confirmation': {
    component: LogoutConfirmation,
    props: (node) => ({
      redirectUrl: node.getAttribute('data-redirect-url'),
      hasAutoRedirect: node.getAttribute('data-has-auto-redirect')
    })
  },
  '#not-found': {
    component: NotFound
  },
  '#registration': {
    component: Registration,
    props: (node) => {
      const providersData = node.getAttribute('data-providers')
      const providers = JSON.parse(providersData).map((item) => item.Name)
      const mtCaptchaTestKey = node.getAttribute('mt-captcha-test-key')
      return { providers, mtCaptchaTestKey }
    }
  },
  '#send-activation-email': {
    component: SendActivationEmail,
    props: (node) => ({
      id: node.getAttribute('data-user-id'),
      email: node.getAttribute('data-user-email')
    })
  },
  '#set-password': {
    component: SetPassword
  },
  '#set-password-error': {
    component: SetPasswordError
  },
  '#verify-totp-code': {
    component: VerifyTotpCode
  },
  '#enable-mfa': {
    component: MultiFactorAuthentication,
    props: (node) => ({
      multiFactorKey: node.getAttribute('data-mfa-key'),
      qrCodeDataUri: node.getAttribute('data-qr-code-uri')
    })
  },
  '#verify-local-password': {
    component: VerifyLocalPassword,
    props: (node) => {
      const authenticationSchemes = node.getAttribute('data-authentication-schemes')
      const antiForgeryToken = document.getElementsByName('__RequestVerificationToken')[0].value
      return {
        authenticationSchemes: JSON.parse(authenticationSchemes),
        antiForgeryToken
      }
    }
  },
  '#error-invalid-code': {
    component: ErrorInvalidCode
  }
}

function mountComponent(selector, Component, props = {}) {
  const app = createApp(Component, props)
  app.use(vuetify)
  app.use(VTooltip, {
    defaultBoundariesElement: document.body
  })
  app.mount(selector)
}

document.addEventListener('DOMContentLoaded', () => {
  Object.keys(componentsMap).forEach((selector) => {
    const node = document.querySelector(selector)

    if (node) {
      const { component, props } = componentsMap[selector]

      mountComponent(
        selector,
        component,
        typeof props === 'function' ? props(document.querySelector(selector)) : props
      )
    }
  })
})
