<template>
  <div class="logo">
    <svg
      class="logo__img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122 26"
    >
      <g>
        <path
          stroke="null"
          id="svg_1"
          d="m17.291365,12.769563c0,-2.2 2.3,-3.5 5.5,-3.5c3.5,0 3.8,0.5 3.8,1.9c0,0.3 -0.1,0.5 -0.4,0.5s-1.8,-0.5 -3.4,-0.5c-1.3,0 -2.5,0.5 -2.5,1.4c0,2.2 7.5,1.8 7.5,5.9c0,2.6 -3.2,3.8 -5.8,3.8c-2.4,0 -4.7,-0.7 -4.7,-0.7s-0.2,-1.1 -0.2,-1.7c0,-0.2 0.2,-0.4 0.4,-0.4c0.2,0 2.4,0.9 4.6,0.9c1.7,0 2.6,-0.8 2.6,-1.7c0,-2.3 -7.4,-2.1 -7.4,-5.9"
          fill="#5F5F64"
        ></path>
        <path
          stroke="null"
          id="svg_2"
          d="m32.491365,4.769563c0,-0.9 1.8,-0.7 2.4,-0.7c0.3,0 0.6,0.2 0.6,0.6l0,6.8c0,0.1 0,0.2 0,0.2c0.4,0 1.9,-2.3 5.1,-2.3c2.6,0 4.6,1.4 4.6,3.8l0,8.3c0,0.4 -0.5,0.6 -0.7,0.6l-1.6,0c-0.3,0 -0.7,-0.2 -0.7,-0.6l0,-7.1c0,-1.8 -0.3,-2.8 -2.1,-2.8c-2.7,0 -4.6,2.6 -4.6,2.6l0,7.4c0,0.3 -0.3,0.6 -0.7,0.6l-1.6,0c-0.4,0 -0.7,-0.3 -0.7,-0.6l0,-16.8z"
          fill="#5F5F64"
        ></path>
        <path
          stroke="null"
          id="svg_3"
          d="m55.691365,8.769563c-1.5,0 -3.1,0.2 -3.8,0.5c-0.7,0.3 -0.8,0.6 -0.8,1.4c0,0.4 0.1,1 0.2,1c0.2,0 2.1,-0.8 4.4,-0.8c2.6,0 3.5,0.5 3.5,2.2c0,0.2 0,0.4 -0.3,0.4c-5.2,0 -9.1,1.2 -9.1,4.7c0,2.7 2.4,4.1 4.5,4.1c2.8,0 4.2,-1.5 4.8,-1.5c0.4,0 0.2,1.1 1.2,1.1l1.1,0c0.4,0 0.5,-0.3 0.5,-0.6l0,-7.6c0.2,-3.1 -1.2,-4.9 -6.2,-4.9m3.5,9.4c0,0.8 -2.4,1.8 -3.9,1.8c-1.1,0 -2.6,-0.4 -2.6,-2c0,-2.8 5.1,-2.6 6.2,-2.6c0.1,0 0.3,0 0.3,0l0,2.8z"
          fill="#5F5F64"
        ></path>
        <path
          stroke="null"
          id="svg_4"
          d="m75.791365,9.269563c-3.2,0 -5,2.3 -5,2.3s-0.2,-0.6 -0.3,-1.2c-0.2,-0.6 -0.4,-0.8 -0.9,-0.8l-1.1,0c-0.2,0 -0.7,0.1 -0.7,0.6l0,15.7c0,0.4 0.3,0.6 0.5,0.6l2,0c0.4,0 0.5,-0.3 0.5,-0.6l0,-5.1c0,0 1.6,1.5 4.5,1.5c3.9,0 6.4,-3.3 6.4,-6.9c0,-3.8 -2.3,-6.1 -5.9,-6.1m-1.1,11c-1.9,0 -3.9,-1 -3.9,-2l0,-4.9c0,0 1.9,-1.9 4.2,-1.9c2.3,0 3.4,1.5 3.4,4.2c0.1,2.2 -1,4.6 -3.7,4.6"
          fill="#5F5F64"
        ></path>
        <path
          stroke="null"
          id="svg_5"
          d="m88.691365,16.469563c0,-0.2 0.1,-0.3 0.3,-0.3l8.7,0c0.3,0 0.7,-0.2 0.7,-0.8c0,-3.5 -1.9,-6.2 -6,-6.2c-3.7,0 -6.9,2.4 -6.9,6.4c0,4 3.6,6.5 7.9,6.5c1.7,0 4.6,-0.3 4.8,-1.2c0.1,-0.4 0.2,-1.6 0.2,-1.6s-1.9,0.9 -4.5,0.9c-3.8,0.2 -5.2,-2.1 -5.2,-3.7m3.5,-5.3c2,0 3.1,1.1 3.1,2.8c0,0.3 -0.2,0.3 -0.5,0.3l-6.2,0c0.1,-0.3 0.2,-3.1 3.6,-3.1"
          fill="#5F5F64"
        ></path>
        <path
          stroke="null"
          id="svg_6"
          d="m4.991365,16.069563c-0.4,0 -0.6,-0.2 -0.6,-0.6l0,-0.6c0,-0.4 0.2,-0.6 0.6,-0.6l0.9,0c1.9,0 3.6,-0.5 3.6,-1.9c0,-1 -1.5,-1.4 -3.1,-1.4c-2.1,0 -4.3,0.8 -4.3,0.8s0.1,-0.3 0.1,-1c0,-0.2 0.1,-0.7 0.1,-0.7c0,-0.6 1.9,-0.9 4.1,-0.9c3.8,0 5.8,1.1 5.8,2.9c0,2.3 -3.4,3 -3.4,3s4,0.4 4,3.3c0,2.1 -2,3.8 -6.5,3.8c-2.1,0 -4.2,-0.4 -4.2,-0.4s-0.3,-1.2 -0.3,-1.6c0,-0.2 0.2,-0.4 0.4,-0.4c0.2,0 2.7,0.6 4,0.6c2.1,0 3.7,-0.5 3.7,-1.9c0,-1.7 -1.7,-2.4 -4.6,-2.4l-0.3,0z"
          fill="#5F5F64"
        ></path>
        <path
          stroke="null"
          id="svg_7"
          d="m106.991365,1.769563c-0.5,-0.3 -1.1,-0.2 -1.3,0.2c-0.2,0.3 -0.3,0.7 -0.3,0.9c0,0.2 -2,11.8 -2.1,12.5c0,0.4 0.1,0.7 0.4,0.9c0.2,0.2 0.5,0.2 0.7,0.2c0.5,-0.1 14.6,-3.8 15.4,-3.9c0.2,0 0.5,-0.2 0.6,-0.4c0.3,-0.4 0.2,-1 -0.2,-1.3c-0.6,-0.5 -12.5,-8.6 -13.2,-9.1m-0.6,5c0.3,-1 0.3,-1.7 0.6,-2.1c0.2,-0.3 0.6,-0.4 0.9,-0.2c0.5,0.4 0.6,1.4 0.2,2.2c-0.4,0.8 -3.2,6.5 -3.2,6.5s0.8,-3.9 1.5,-6.4m10,3.7c0,0.1 0,0.2 -0.1,0.3c-0.2,0.2 -0.5,0.4 -0.8,0.5c-0.3,0.1 -8.5,3.3 -8.8,3.4c-0.3,0.1 -0.9,0.4 -1.1,0.2c-0.3,-0.2 -0.1,-0.6 0.1,-1c0.8,-1.2 3.8,-5.7 4.1,-6.3c0.5,-0.7 1.3,-1 2,-0.5c0.8,0.6 4,2.8 4.2,2.9c0.2,0.2 0.4,0.3 0.4,0.5"
          fill="#C8143C"
        ></path>
      </g>
    </svg>
  </div>
</template>

<script src="./Logo.js"></script>

<style src="./Logo.scss" lang="scss" scoped></style>
