const Stepper = {
  name: 'Stepper',

  props: {
    steps: {
      type: Array,
      required: true
    },
    activeStep: {
      type: [Number, String],
      default: null
    },
    isCompleted: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: null
    }
  }
}

export default Stepper
