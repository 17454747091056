import Logo from '@/uikit/Logo/Logo.vue'

const Header = {
  name: 'Header',

  components: {
    Logo
  }
}

export default Header
