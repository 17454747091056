<template>
  <div class="consents-acceptance-pending">
    <div class="consents-acceptance-pending__container">
      <div class="consents-acceptance-pending__title">
        3Shape Terms and Conditions
        <br />
        Pending Acceptance
      </div>
      <div class="consents-acceptance-pending__text-container">
        <div class="consents-acceptance-pending__img-container">
          <img
            class="consents-acceptance-pending__img"
            :src="images.userMessage"
            alt=""
          />
        </div>

        <div>
          <div class="consents-acceptance-pending__text">
            {{ `Hi,${userName ? ` ${userName}` : ''}` }}
          </div>
          <div class="consents-acceptance-pending__text">
            Your access to
            {{ softwareName ? softwareName : 'software' }}
            is currently restricted until the necessary legal agreements are accepted by your
            company administrator.
          </div>
          <div class="consents-acceptance-pending__text">
            Please have your company administrator for 3Shape Software log in using their 3Shape
            Account. They need to review and accept the legal agreements to ensure uninterrupted
            access to our services.
          </div>
        </div>
      </div>
      <div class="consents-acceptance-pending__button-container">
        <Button
          auto-qa-id="sign-out-button"
          :on-click="onLogout"
        >
          Sign Out
        </Button>
      </div>
    </div>

    <div class="consents-acceptance-pending__support-text">
      Questions? Contact us at
      <Link
        auto-qa-id="support-link"
        href="mailto:support@3shape.com"
      >
        support@3shape.com
      </Link>
    </div>
  </div>
</template>

<script src="./ConsentsAcceptancePending.js"></script>

<style src="./ConsentsAcceptancePending.scss" lang="scss" scoped></style>
