import { reactive } from 'vue'
import { CodeVerification } from 'account-ui-components'
import ModalAuthenticatorAppHelp from '@/components/_modals/ModalAuthenticatorAppHelp/ModalAuthenticatorAppHelp.vue'

export default {
  name: 'VerifyMfaCode',

  props: {
    loading: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    clearVerificationError: {
      type: Function,
      required: true
    },
    onVerifyConfirm: {
      type: Function,
      required: true
    }
  },

  components: {
    CodeVerification,
    ModalAuthenticatorAppHelp
  },

  setup() {
    const modals = reactive({
      help: false
    })

    const toggleModal = (modal) => {
      modals[modal] = !modals[modal]
    }

    return {
      modals,
      toggleModal
    }
  }
}
