<template>
  <Page>
    <div class="login">
      <div class="login__container">
        <div
          v-if="logoUri && clientName"
          class="login__client-info-container"
        >
          <img
            class="login__client-logo"
            :src="logoUri"
            :alt="`${clientName} logo`"
          />
          <div class="login__client-name">
            {{ clientName }}
          </div>
        </div>
        <div
          v-if="step !== 'email'"
          class="login__back-button"
          title="Back"
          @click="onBackButtonClick"
        >
          <svg
            class="login__back-button-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10.405 20.123"
          >
            <path
              d="M9.66 19.416.5 10.073 9.698.708"
              class="color"
              fill="none"
              stroke="#5f5f64"
              stroke-width="2px"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="login__title">Sign in with 3Shape Account</div>

        <template v-if="showSignUp">
          <div class="login__create-account-link-container">
            Don't have an account?

            <Link
              href="/registration"
              auto-qa-id="sign-up-link"
            >
              Sign up
            </Link>
          </div>
        </template>

        <div class="login__form">
          <template v-if="step === 'email'">
            <Input
              ref="emailField"
              label="Email"
              name="username"
              type="email"
              autocomplete="username"
              required
              :disabled="emailFieldDisabled"
              :value="fields.username"
              :error="errors.username"
              auto-qa-id="email-input"
              :onChange="onInputChange"
              :onBlur="onInputBlur"
              :onEnterKeyPress="onNext"
            />

            <input
              type="hidden"
              name="password"
              autocomplete="current-password"
            />

            <div class="login__button-container">
              <Button
                auto-qa-id="next-button"
                :onClick="onNext"
              >
                Next
              </Button>
            </div>
          </template>
          <template v-else-if="step === 'sso'">
            <div class="login__email-container">
              <img
                class="login__user-icon"
                :src="images.userIcon"
                alt=""
              />

              <div class="login__email">
                {{ fields.username }}
              </div>
            </div>

            <div class="login__text">
              Your organization uses Single Sign On (SSO) with 3Shape. Please sign in using your SSO
              credentials.
            </div>

            <div class="login__button-container">
              <Button
                v-show="!isLoading"
                block
                auto-qa-id="sign-in-button"
                :onClick="onSSOSubmit"
              >
                Sign in
              </Button>

              <Preloader v-show="isLoading" />
            </div>

            <div class="login__sign-in-without-sso-link-container">
              <Link
                auto-qa-id="sing-in-without-sso-link"
                :onClick="onLoginWithoutSSO"
              >
                Sign in without SSO
              </Link>
            </div>

            <form
              ref="sInternalForm"
              method="post"
              :action="sInternalAction"
            >
              <input
                type="hidden"
                name="provider"
                :value="fields.provider"
              />

              <input
                type="hidden"
                name="username"
                :value="fields.username"
              />

              <input
                type="hidden"
                name="__RequestVerificationToken"
                :value="antiForgeryToken"
              />
            </form>
          </template>
          <template v-else>
            <form
              ref="signInForm"
              :action="action"
              method="post"
            >
              <div class="login__email-container">
                <img
                  class="login__user-icon"
                  :src="images.userIcon"
                  alt=""
                />

                <input
                  class="login__email"
                  type="email"
                  name="username"
                  :value="fields.username"
                  readonly
                  tabindex="-1"
                  data-auto-qa-id="email-input"
                />
              </div>

              <div class="login__input-container">
                <PasswordInput
                  ref="passwordField"
                  name="password"
                  required
                  :value="fields.password"
                  :error="errors.password"
                  auto-qa-id="password-input"
                  :onChange="onInputChange"
                  :onBlur="onInputBlur"
                  :onEnterKeyPress="onSubmit"
                />
              </div>

              <div class="login__forgot-password-link-container">
                <a
                  class="login__forgot-password-link"
                  href="/account/forgotpassword"
                  data-auto-qa-id="forgot-password-link"
                >
                  Forgot your password?
                </a>
              </div>

              <div
                v-if="isCaptchaActive"
                class="login__captcha-container"
              >
                <input
                  type="hidden"
                  name="captchaToken"
                  :value="captchaToken"
                />

                <Captcha
                  :error="errors.captcha"
                  :onVerificationChanged="onCaptchaUpdated"
                  :mtCaptchaTestKey="mtCaptchaTestKey"
                />
              </div>

              <div class="login__button-container">
                <Button
                  v-show="!isLoading"
                  block
                  auto-qa-id="sign-in-button"
                  :onClick="onSubmit"
                >
                  Sign in
                </Button>

                <Preloader v-show="isLoading" />
              </div>

              <input
                type="hidden"
                name="__RequestVerificationToken"
                :value="antiForgeryToken"
              />
            </form>
          </template>
        </div>

        <div class="login__policy-container">
          Please find more about processing personal data by 3Shape and about your rights in our

          <a
            class="login__policy-link"
            href="https://www.3shape.com/en/privacy-policy"
            target="_blank"
            data-auto-qa-id="privacy-policy-link"
          >
            Privacy Policy
          </a>
          <div class="separator">&</div>
          <a
            class="login__policy-link"
            href="https://www.3shape.com/en/cookies-policy"
            target="_blank"
            data-auto-qa-id="cookies-policy-link"
          >
            Cookies Policy
          </a>
          .
        </div>
      </div>
    </div>

    <modal-email-already-in-use
      v-if="modals.emailAlreadyInUse"
      :on-close="
        () => {
          toggleModal('emailAlreadyInUse')
        }
      "
    ></modal-email-already-in-use>
  </Page>
</template>

<script src="./Login.js"></script>

<style src="./Login.scss" lang="scss" scoped></style>
