<template>
  <Page>
    <div class="verify-totp-code">
      <div
        v-if="loading.component"
        class="verify-totp-code__preloader-container"
      >
        <Preloader />
      </div>

      <template v-else>
        <div class="verify-totp-code__img-container">
          <img
            class="verify-totp-code__img"
            :src="images.superman"
            alt=""
          />
        </div>

        <div class="verify-totp-code__title">Multi Factor Authentication</div>

        <div class="verify-totp-code__text">Please enter your two factor authentication code</div>
        <code-verification
          :loading="loading"
          :on-verification="onVerifyConfirm"
        ></code-verification>

        <div
          v-if="isCodeConfirmed"
          class="verify-totp-code__confirmation"
        >
          Code is confirmed
        </div>
      </template>
    </div>
  </Page>
</template>

<script src="./VerifyTotpCode.js"></script>

<style src="./VerifyTotpCode.scss" lang="scss" scoped></style>
