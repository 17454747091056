import { ref, computed, reactive, onMounted } from 'vue'
import { Modal, Checkbox, Link, Button } from 'account-ui-components'
import { getEnv } from '@/helpers'
import config from '@/api/config'
import Page from '@/components/Page/Page.vue'
import PdfViewer from '@/components/PdfViewer/PdfViewer.vue'
import ConsentsAcceptancePending from '@/components/ConsentsAcceptancePending/ConsentsAcceptancePending.vue'
import CloudStorage from '@/components/CloudStorage/CloudStorage.vue'
import CompanyIcon from '@/assets/img/company.svg'
import UserMessageIcon from '@/assets/img/user-message.svg'

const Consents = {
  name: 'Consents',

  props: {
    userName: {
      type: String,
      default: null
    },
    softwareName: {
      type: String,
      default: null
    },
    items: {
      type: String,
      required: true
    }
  },

  components: {
    Page,
    PdfViewer,
    ConsentsAcceptancePending,
    CloudStorage,
    Modal,
    Checkbox,
    Link,
    Button
  },

  setup(props) {
    const images = {
      company: CompanyIcon,
      user: UserMessageIcon
    }
    const env = getEnv()
    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')
    const antiForgeryToken = antiForgeryField ? antiForgeryField.value : null
    const urlParams = new URLSearchParams(window.location.search)
    const returnUrl = urlParams.get('returnUrl')
    const consents = ref(null)
    const cloudStorageSelectedOption = ref(true)
    const parsedItems = JSON.parse(props.items)
    consents.value = parsedItems
      .filter((item) => item.CanAccept)
      .map((item, index) => {
        const consent = item
        consent.checked = false
        consent.index = index
        return consent
      })
      .sort((a, b) => Number(b.RequirementOptions) - Number(a.RequirementOptions))
    const pdfUrl = ref(null)
    const form = ref(null)
    const loading = reactive({
      submit: false
    })
    const modals = reactive({
      consentDetails: false
    })
    const hasUnsignableConsents = computed(() =>
      parsedItems.some((consent) => {
        const canSign = consent.CanAccept
        const isConsentRequired = consent.RequirementOptions === 1
        const isConsentNonRetractable = consent.RequirementOptions === 2
        const isAcceptedInPreviousVersion = consent.HasConsentForPreviousVersion
        const currentDate = new Date()
        const gracePeriodEndData = new Date(consent.GracePeriodEndDate)
        const isGracePeriodEnded = gracePeriodEndData <= currentDate

        return (
          !canSign &&
          (isConsentRequired || (isConsentNonRetractable && isAcceptedInPreviousVersion)) &&
          isGracePeriodEnded
        )
      })
    )
    const cloudStorage = computed(() => {
      const agreement = consents.value.find(
        (consent) =>
          consent.RequirementOptions === 2 &&
          consent.Type === 'CompanyAgreement' &&
          consent.Name === 'Cloud storage'
      )

      if (agreement) {
        agreement.checked = false
        const reviver = (key, value) => {
          if (
            typeof value === 'string' &&
            (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')
          ) {
            return value.toLowerCase() === 'true'
          }
          return value
        }

        if (agreement.Label) {
          agreement.LabelParsed = JSON.parse(agreement.Label, reviver)
        }
      }

      return agreement
    })
    const companyConsents = computed(() =>
      consents.value.filter(
        (consent) =>
          consent.Type === 'CompanyAgreement' &&
          (!cloudStorage.value || consent.AgreementId !== cloudStorage.value.AgreementId)
      )
    )
    const userConsents = computed(() =>
      consents.value.filter((consent) => consent.Type !== 'CompanyAgreement')
    )
    const isSubmitDisabled = computed(() =>
      consents.value.some((consent) => {
        const isRequired = consent.RequirementOptions === 1
        const isCloudStorageSelected = cloudStorageSelectedOption.value !== ''
        return (isRequired && !consent.checked) || (cloudStorage.value && !isCloudStorageSelected)
      })
    )
    const isCloudStorageAccepted = computed(() => cloudStorageSelectedOption.value)

    const onSubmit = () => {
      if (!isSubmitDisabled.value) {
        loading.submit = true

        form.value.submit()
      }
    }

    const onKeyDown = (event) => {
      if (event.code === 'Enter') {
        onSubmit()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', onKeyDown)
    })

    const toggleModal = (name) => {
      modals[name] = !modals[name]
    }

    const onConsentClick = (event, consent) => {
      event.preventDefault()
      event.stopPropagation()

      pdfUrl.value = `${config.hosts[env].consentsApi}/api/v1/agreements/${consent.AgreementId}/versions/${consent.VersionId}/file`

      toggleModal('consentDetails')
    }

    const onCheckboxChange = (index, type) => {
      const dataObject = type === 'company' ? companyConsents : userConsents

      dataObject.value[index].checked = !dataObject.value[index].checked
    }

    const onLogout = () => {
      loading.submit = true

      window.location.href = '/account/logout'
    }

    const onCloudStorageOptionClick = (value) => {
      cloudStorageSelectedOption.value = value
    }

    return {
      images,
      antiForgeryToken,
      returnUrl,
      pdfUrl,
      loading,
      modals,
      hasUnsignableConsents,
      form,
      cloudStorage,
      companyConsents,
      userConsents,
      isSubmitDisabled,
      cloudStorageSelectedOption,
      isCloudStorageAccepted,
      toggleModal,
      onConsentClick,
      onCheckboxChange,
      onSubmit,
      onLogout,
      onCloudStorageOptionClick
    }
  }
}

export default Consents
