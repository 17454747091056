import { reactive, onMounted } from 'vue'
import { Preloader, Button } from 'account-ui-components'
import { preloadImages } from '@/helpers'
import googlePlayIcon from '@/assets/img/google-play.svg'
import googlePlayQrIcon from '@/assets/img/google-play-qr.svg'
import appStoreIcon from '@/assets/img/app-store.svg'
import appStoreQrIcon from '@/assets/img/app-store-qr.svg'

export default {
  name: 'DownloadAuthenticatorApp',

  props: {
    onBackClick: {
      type: Function,
      required: true
    },
    onNextClick: {
      type: Function,
      required: true
    }
  },

  components: {
    Preloader,
    Button
  },

  setup() {
    const loading = reactive({
      component: true
    })
    const images = {
      googlePlay: googlePlayIcon,
      googlePlayQr: googlePlayQrIcon,
      appStore: appStoreIcon,
      appStoreQr: appStoreQrIcon
    }

    onMounted(async () => {
      await preloadImages(Object.values(images))

      loading.component = false
    })

    return {
      loading,
      images
    }
  }
}
