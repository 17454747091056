<template>
  <Modal :on-close="onClose">
    <div class="modal-authenticator-app-help">
      <div class="modal-authenticator-app-help__title">Help with your authenticator app</div>

      <div class="modal-authenticator-app-help__container">
        <div>
          <div class="modal-authenticator-app-help__text">
            An authenticator app is a phone app that generates a one-time code that changes every 30
            seconds.
          </div>
          <!-- prettier-ignore -->
          <div class="modal-authenticator-app-help__text">
            We have recommended
            <div class="modal-authenticator-app-help__highlighted-text">Google Authenticator</div>,
            but any authenticator app will work.
          </div>
          <div class="modal-authenticator-app-help__text">
            Open the authenticator app you installed during the two-factor verification setup and
            find the code next to 3Shape Account. Enter the shown code before the time expires to
            complete your sign in.
          </div>
        </div>
        <div class="modal-authenticator-app-help__icon-container">
          <img
            :src="images.smartphoneHelp"
            alt=""
          />
        </div>
      </div>

      <div class="modal-authenticator-app-help__button-container">
        <Button
          variant="outlined"
          :onClick="onClose"
        >
          Close
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script src="./ModalAuthenticatorAppHelp.js"></script>

<style src="./ModalAuthenticatorAppHelp.scss" lang="scss" scoped></style>
