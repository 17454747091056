import { Radio } from 'account-ui-components'
import cloudStorageIcon from '@/assets/img/cloud-storage.svg'

export default {
  name: 'CloudStorage',

  props: {
    data: {
      type: Object,
      required: true
    },
    selectedOption: {
      type: Boolean,
      default: ''
    },
    onOptionClick: {
      type: Function,
      required: true
    }
  },

  components: {
    Radio
  },

  setup() {
    const images = {
      cloudStorage: cloudStorageIcon
    }

    return {
      images
    }
  }
}
