<template>
  <Modal :on-close="onClose">
    <div class="modal-email-already-in-use">
      <div class="modal-email-already-in-use__title">Email already in use</div>

      <div class="modal-email-already-in-use__text">
        Your sign-in email is already in use with 3Shape Account.
        <br />
        Sign in with your password instead and open your
        <b>Personal settings,</b>
        where you can enable
        <b>single sign-on.</b>
      </div>

      <div class="modal-email-already-in-use__button-container">
        <Button :on-click="onClose">Close</Button>
      </div>
    </div>
  </Modal>
</template>

<script src="./ModalEmailAlreadyInUse.js"></script>

<style src="./ModalEmailAlreadyInUse.scss" lang="scss" scoped></style>
