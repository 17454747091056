<template>
  <div class="not-found">
    <svg
      class="not-found__image"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 392.512 140.078"
    >
      <path
        fill="#5f5f64"
        d="M367.561 95.72h24.951v3.754h-24.951v38.5h-4.215v-38.5h-71.017v-3.308l69.918-90.282h5.309zm-70.132 0h65.918V10.309zM75.231 95.72h25.017v3.754H75.231v38.5h-4.214v-38.5H0v-3.308L69.918 5.884h5.309zm-70.132 0h65.939V10.309zM195.625 140.082a61.281 61.281 0 01-60.211-62.192V17.157l.444-.57c.467-.611 11.868-15.105 26.556-15.105a39.786 39.786 0 0114.875 2.762 44.36 44.36 0 0016.859 2.95 29.966 29.966 0 0015-3.372A33.717 33.717 0 01226.014.024c17.491 0 28.913 15.953 29.4 16.627l.379.548v60.712a61.281 61.281 0 01-60.169 62.171zm-55.993-121.45v59.3a56.01 56.01 0 10111.963 0v-59.4c-2.107-2.613-11.927-14.33-25.562-14.33a29.671 29.671 0 00-14.983 3.393 33.717 33.717 0 01-16.86 3.772 48.6 48.6 0 01-18.313-3.185 35.3 35.3 0 00-13.487-2.529c-11.146.04-20.651 10.472-22.758 12.979z"
      />
      <path
        fill="#5f5f64"
        d="M195.245 42.888a47.12 47.12 0 01-26.3-7.86 2.107 2.107 0 112.55-3.245c.864.632 21.474 15.742 48.469-.15a2.107 2.107 0 112.149 3.625 52.072 52.072 0 01-26.868 7.63z"
      />
      <path
        d="M220.849 110.811a2.107 2.107 0 01-1.665-.823 30.051 30.051 0 00-42.147-5.5 30.7 30.7 0 00-5.205 5.121 2.107 2.107 0 01-3.29-2.613 33.718 33.718 0 015.922-5.837 34.2 34.2 0 0148.008 6.322 2.032 2.032 0 01-1.623 3.33z"
        fill="#d1004b"
      />
      <path
        fill="#5f5f64"
        d="M170.21 83.455a2.107 2.107 0 01-.8-4.046l8.724-3.6-9.23-7.334a2.108 2.108 0 112.613-3.308l12.053 9.567a2.12 2.12 0 01-.523 3.6l-12.035 4.951a1.77 1.77 0 01-.802.17zM220.849 83.454a1.77 1.77 0 01-.8-.168l-12.034-4.952a2.107 2.107 0 01-.506-3.6l12.033-9.567a2.108 2.108 0 112.613 3.308l-9.23 7.333 8.724 3.6a2.107 2.107 0 01-.8 4.046z"
      />
    </svg>

    <div class="not-found__title">It looks like this page is not available</div>

    <div class="not-found__text">
      The link you followed may be broken, or this page may have been removed.
    </div>

    <Button
      auto-qa-id="go-back-button"
      :onClick="onClick"
    >
      Go back
    </Button>
  </div>
</template>

<script src="./NotFound.js"></script>

<style src="./NotFound.scss" lang="scss" scoped></style>
