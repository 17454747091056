<template>
  <div class="registration-verify-with-short-code">
    <div class="registration-verify-with-short-code__img-container">
      <img
        class="registration-verify-with-short-code__img"
        :src="images.supermanIcon"
        alt=""
      />
    </div>

    <div class="registration-verify-with-short-code__greeting">Verify your account</div>

    <div class="registration-verify-with-short-code__text">
      Please enter the code we have sent to the email account
      <div class="registration-verify-with-short-code__highlighted-text">{{ email }}</div>
      , or click the link in the email.
    </div>
    <div class="registration-verify-with-short-code__sub-text">
      This link is valid for the next
      <div class="registration-verify-with-short-code__highlighted-text">48 hours</div>
      .
    </div>

    <code-verification
      :loading="loading"
      :error="errors.code"
      :on-change="clearCodeVerificationError"
      :on-focus="clearCodeVerificationError"
      :on-verification="onVerifyConfirm"
    ></code-verification>
  </div>
</template>

<script src="./RegistrationVerifyWithShortCode.js"></script>

<style src="./RegistrationVerifyWithShortCode.scss" lang="scss" scoped></style>
