<template>
  <div class="captcha">
    <div class="captcha__container">
      <div
        class="mtcaptcha"
        :id="captchaId"
      ></div>
    </div>

    <div
      v-if="error"
      class="captcha__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script src="./Captcha.js"></script>

<style src="./Captcha.scss" lang="scss" scoped></style>
