import { Title, Link } from 'account-ui-components'
import Page from '@/components/Page/Page.vue'
import toothIcon from '@/assets/img/tooth.svg'

export default {
  name: 'Error',

  props: {
    errorTitle: {
      type: String,
      required: false
    },
    errorDescription: {
      type: String,
      required: false
    }
  },

  components: {
    Page,
    Title,
    Link
  },

  setup() {
    const images = {
      tooth: toothIcon
    }

    return {
      images
    }
  }
}
