import { CodeVerification, Preloader } from 'account-ui-components'
import { ref, reactive, onMounted } from 'vue'
import Api from '@/api'
import Stepper from '@/uikit/Stepper/Stepper.vue'
import Page from '@/components/Page/Page.vue'
import questionMarkIcon from '@/assets/img/questionmark.svg'

const ForgotPasswordConfirmation = {
  name: 'ForgotPasswordConfirmation',

  components: {
    Stepper,
    Page,
    CodeVerification,
    Preloader
  },

  setup() {
    const images = {
      questionMark: questionMarkIcon
    }
    const email = ref('')
    const loading = reactive({
      component: false,
      confirm: false,
      resend: false
    })
    const errors = reactive({
      code: ''
    })
    const mfaEnabled = ref(false)

    onMounted(() => {
      const storageEmail = localStorage.getItem('email')

      if (storageEmail) {
        email.value = storageEmail
      }
    })

    const onVerifyConfirm = async (code) => {
      loading.confirm = true

      try {
        await Api.verifyForgotPasswordCode(email.value, code)

        window.location.href = `/account/resetpassword?email=${email.value}&code=${code}`

        localStorage.removeItem('email')
      } catch (err) {
        errors.code = 'Incorrect code. Enter the code again.'
      }

      loading.confirm = false
    }

    const clearCodeVerificationError = () => {
      if (errors.code) {
        errors.code = ''
      }
    }

    return {
      images,
      email,
      loading,
      errors,
      mfaEnabled,
      onVerifyConfirm,
      clearCodeVerificationError
    }
  }
}

export default ForgotPasswordConfirmation
