<template>
  <Page>
    <div class="error-invalid-code">
      <img
        class="error-invalid-code__img"
        :src="images.tooth"
        alt=""
      />

      <div class="error-invalid-code__title-container">
        <Title>Activation Link Issue</Title>
      </div>

      <div class="error-invalid-code__text">
        The link you used is invalid or has expired. If you have already activated your account, you
        can
        <Link
          auto-qa-id="sign-in-link"
          href="/account/login"
        >
          sign in
        </Link>
        .
        <br />
        If your account is not yet activated, please request a new activation link or contact
        support for further assistance at
        <Link
          auto-qa-id="contact-us-link"
          href="mailto:support@3shape.com"
          target="_blank"
        >
          support@3shape.com
        </Link>
      </div>
    </div>
  </Page>
</template>

<script src="./ErrorInvalidCode.js"></script>

<style src="./ErrorInvalidCode.scss" lang="scss" scoped></style>
